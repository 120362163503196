import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const env = createEnv({
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars.
   */
  server: {
    NODE_ENV: z.enum(["development", "test", "production"]),
    // Add `.min(1) on ID and SECRET if you want to make sure they're not empty
    COGNITO_CLIENT_ID: z.string().min(1),
    COGNITO_USER_POOL_ID: z.string().min(1),
    COGNITO_URL: z.string().min(1),
    PUBLIC_URL: z.string().url(),
    SST_STAGE: z.string().min(1),
  },

  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars. To expose them to the client, prefix them with
   * `NEXT_PUBLIC_`.
   */
  client: {
    // NEXT_PUBLIC_CLIENTVAR: z.string().min(1),
    NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY: z.string().min(1),
    NEXT_PUBLIC_DEPLOY_REGION: z.string().min(1),
    NEXT_PUBLIC_APPSYNC_API_URL: z.string(),
    NEXT_PUBLIC_BOOK_MEETING_URL: z.string(),
    NEXT_PUBLIC_TRPC_API_DOMAIN_NAME: z.string().min(1),
    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: z.string().min(1),
    NEXT_PUBLIC_GOOGLE_DEVELOPER_API_KEY: z.string().min(1),
    NEXT_PUBLIC_GOOGLE_APP_ID: z.string().min(1),
  },

  /**
   * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
   * middlewares) or client-side so we need to destruct manually.
   */
  runtimeEnv: {
    NODE_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_DEPLOY_REGION: process.env["NEXT_PUBLIC_DEPLOY_REGION"],
    COGNITO_CLIENT_ID: process.env["COGNITO_CLIENT_ID"],
    COGNITO_USER_POOL_ID: process.env["COGNITO_USER_POOL_ID"],
    COGNITO_URL: process.env["COGNITO_URL"],
    PUBLIC_URL: process.env["PUBLIC_URL"],
    NEXT_PUBLIC_APPSYNC_API_URL: process.env["NEXT_PUBLIC_APPSYNC_API_URL"],
    SST_STAGE: process.env["SST_STAGE"],
    NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY:
      process.env["NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY"],
    NEXT_PUBLIC_BOOK_MEETING_URL: process.env["NEXT_PUBLIC_BOOK_MEETING_URL"],
    NEXT_PUBLIC_TRPC_API_DOMAIN_NAME:
      process.env["NEXT_PUBLIC_TRPC_API_DOMAIN_NAME"],
    // NEXT_PUBLIC_CLIENTVAR: process.env.NEXT_PUBLIC_CLIENTVAR,
    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY:
      process.env["NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY"],
    NEXT_PUBLIC_GOOGLE_DEVELOPER_API_KEY:
      process.env["NEXT_PUBLIC_GOOGLE_DEVELOPER_API_KEY"],
    NEXT_PUBLIC_GOOGLE_APP_ID: process.env["NEXT_PUBLIC_GOOGLE_APP_ID"],
  },
  /**
   * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation.
   * This is especially useful for Docker builds.
   */
  skipValidation: !!process.env["SKIP_ENV_VALIDATION"],
});
