"use client";

import { createContext } from "react";

import type { LOCALE_VALUES } from "@cloudifybiz/lighthouse-core/constants";

export type UserContext = {
  locale: (typeof LOCALE_VALUES)[number];
};

export const userContext = createContext<undefined | UserContext>(undefined);

export const UserProvider = ({
  children,
  user,
}: {
  children: React.ReactNode;
  user?: UserContext;
}) => {
  return <userContext.Provider value={user}>{children}</userContext.Provider>;
};
