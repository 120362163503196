"use client";

import {
  createContext,
  useContext,
  useState,
  type ReactNode,
  useCallback,
} from "react";

export const StepDoneContext = createContext<{
  stepDone?: (key: string) => boolean;
  setStepDone?: (key: string, value: boolean) => void;
}>({});

export const StepDoneProvider = ({ children }: { children: ReactNode }) => {
  const [stepDone, setStepDone] = useState<Record<string, boolean>>({});
  const stepDoneCallback = useCallback(
    (key: string) => stepDone[key] ?? false,
    [stepDone],
  );
  const setStepDoneCallback = useCallback(
    (key: string, value: boolean) =>
      setStepDone((prev) => ({ ...prev, [key]: value })),
    [setStepDone],
  );
  return (
    <StepDoneContext.Provider
      value={{
        stepDone: stepDoneCallback,
        setStepDone: setStepDoneCallback,
      }}
    >
      {children}
    </StepDoneContext.Provider>
  );
};

export const useStepDone = () => {
  const { stepDone, setStepDone } = useContext(StepDoneContext);
  return { stepDone, setStepDone };
};
